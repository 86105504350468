/*
jQuery plugin to wrap navigation links under a more menu
if there is not enough space available to show all navigation items in one row.

Created by - Alok Jain
Last modified: 23 November 2020
*/

(function ($) {
	$.fn.navwrap = function (opt) {
		// select existing navigation list
		var nav = $(this);
		nav.show();

		//Set initial variables
		var availableSpace = nav.outerWidth();
		var list = 0;
		var countedSpace = 0;
		var navItems = $(nav).html();

		// Get the computed styles of the nav element
		const navStyles = window.getComputedStyle(nav[0]);

		function countSpace() {
			$(nav)
				.find("li")
				.not(".subnav li")
				.each(function () {
					var current = $(this).first("a");
					countedSpace = countedSpace + current.outerWidth();

					// Check if the nav is using flex for styling
					if (navStyles.display === 'flex') {
						// Get the gap value
						const gap = parseFloat(navStyles.gap) || 0;

						// Include the gap in the countedSpace calculation
						countedSpace += gap;
					}

					if (countedSpace < availableSpace) {
						list++;
					}
				});
		}
		countSpace();

		function createSubMenu() {
			if (countedSpace > availableSpace) {
				var more =
					'<li class="nav-wrap" aria-haspopup="true" aria-expanded="false"><a href="#">More</a></li>';
				nav.prepend(more); //insert sub-menu

				var showInList = list + 1; //Space available for xx no. of items.

				var newList = [];
				// Start at 2, don't include dynamically added more link.
				for (i = 2; i <= showInList; i++) {
					newList.push(
						$(nav)
							.find("li:nth-child(" + i + ")")
							.first()
					);
				}

				newList.push(more);

				var childList = [];
				for (i = showInList; i <= nav.children().length; i++) {
					childList.push($(nav).find("li:nth-child(" + i + ")"));
				}

				nav.html(newList);

				var ulChildren = $("<ul/>").append(childList);
				$(nav).find("li.nav-wrap").append(ulChildren);
			}
		}
		createSubMenu();

		//Browser Resize
		$(window).on("resize", function () {
			//Reset Navigation
			$(nav).empty().html(navItems);
			availableSpace = nav.outerWidth();
			list = 0;
			countedSpace = 0;

			countSpace();
			createSubMenu();
		});

		return this;
	};
})(jQuery);
