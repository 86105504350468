$(document).ready(function () {

	//Wrap navigation to more dropdown link
	$(".nav-sec ul.nav-list").navwrap();

	// remove .fplist-title from .fplist and insert it before .fplist
	$(".fplist-title").insertBefore(".fplist");
	$(".fplist").remove(".fplist-title");

	// Show one testimonial at a time and change every 5 seconds
	let testimonials = $(".testimonial-list-container .testimonial");
	let currentIndex = 0;

	// Create dots navigation
	let dotsContainer = $('<div class="testimonial-dots"></div>');
	testimonials.each(function (index) {
		let dot = $('<span class="dot"></span>');
		dot.on('click', function () {
			currentIndex = index;
			showTestimonial(currentIndex);
		});
		dotsContainer.append(dot);
	});
	$(".testimonial-list-container").append(dotsContainer);

	// Get the maximum height of a testimonial
	let maxHeight = 0;
	testimonials.each(function () {
		let height = $(this).outerHeight();
		if (height > maxHeight) {
			maxHeight = height;
		}
	});

	// Set the container height to the maximum testimonial height
	$(".testimonial-list-container").height(maxHeight);

	function showTestimonial(index) {
		testimonials.fadeOut(400);
		testimonials.eq(index).fadeIn(400);
		updateDots(index);
	}

	function nextTestimonial() {
		currentIndex = (currentIndex + 1) % testimonials.length;
		showTestimonial(currentIndex);
	}

	function updateDots(index) {
		$(".dot").removeClass("active");
		$(".dot").eq(index).addClass("active");
	}

	// Initially show the first testimonial and set the first dot as active
	showTestimonial(currentIndex);

	// Change testimonial every 5 seconds
	setInterval(nextTestimonial, 5000);
});
